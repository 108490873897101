import React from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Menu, notification, Spin } from 'antd'
import { getOneAllData, updateMemberInfo } from '../service/api'
// 设定路由必须使用getAuthority提取authority参数，使用setSideBar函数设置侧边栏
import { setSideBar } from '../utils/auth'
import { PoweroffOutlined } from '@ant-design/icons'
import './index.scss'
import { expertsRoutes } from '../routes/index'
import { getUsername, getUsertype, levelJudge, loginOut } from '../utils/auth'
import { eventBus } from '../utils/commConstant'
import avatar from '../assets/images/avatar.jpg'
import ewCode from '../assets/images/ewCode.jpg'
import moment from 'moment'
import Personmodal from '../components/Personmodal/Personmodal'
import Unitmodal from '../components/Unitmodal/Unitmodal'
import Expertsmodal from '../components/Expertsmodal/Expertsmodal'
import Experthelp from '../components/Experthelp/Experthelp'
import Unithelp from '../components/Unithelp/Unithelp'
import Personhelp from '../components/Personhelp/Personhelp'

const { SubMenu } = Menu;
// 如果使用Layout中Footer标签，需要引入Footer
const { Header, Content, Sider } = Layout;

const routes = setSideBar(expertsRoutes, 'experts');

//个人会员职称
const personZcList = ['正高级职称', '副高级职称', '中级职称', '初级职称', '无', '其他'];
//专家会员职称
const technicalTitle = ['正高级职称', '副高级职称', '中级职称', '初级职称', '其他'];

class Frame extends React.Component {
  state = {
    collapsed: false,
    sideBarActive: '/memberpages/home',
    username: '',
    usercode: '',
    tempRecord: {},
    data: {},
    levelcode: '',
    showModal: false,
    modalTitle: true,
    userType: "",
    showHelpModal: false,
    loading: false,
  };

  UNSAFE_componentWillMount() {
    let userAccount = getUsername();
    let userType = getUsertype();
    let type = null;
    if (userType === '个人用户') {
      type = '0'
    } else if (userType === '单位用户') {
      type = '1'
    } else if (userType === '专家用户') {
      type = '2'
    }
    this.getAllData(userAccount, type)
  }

  componentDidMount() {
    this.setState({
      sideBarActive: this.props.location.pathname  // 解决页面刷新时侧边栏与内容不匹配
    })

    eventBus.addListener('editData', (msg) => {
      this.editData()
    });
  }

  // 获取该用户所有信息
  getAllData = (account, type) => {
    getOneAllData({ account, type }).then(res => {
      // console.log(res, 1111)
      if (res.data.code === 200) {
        let data = res.data.data;

        this.setState({
          data: data,
          username: data.name,
          usercode: data.vId,
          levelcode: levelJudge(type, data.vId?.slice(-1))
        })
      } else {
        return notification.error({
          message: '提示',
          description: '网络错误，请刷新或者通知管理员！'
        })
      }

    })
  };

  // 侧边菜单栏隐藏事件
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };

  // 侧边菜单栏点击选中事件
  onSelect(e) {
    // console.log(e.key, 555555)
    // console.log(this.props, 22225)
    this.setState({
      sideBarActive: e.key
    })
  }

  // 生成多级菜单
  setMultistageMenu = (options) => {
    // console.log(options, 22222)
    return (<SubMenu key={options.path} title={
      <span>
        {options.icon}
        <span>{options.title}</span>
      </span>
    }>
      {options.children.map(optionItem => {
        if (optionItem.children === undefined) {
          return <Menu.Item key={optionItem.path} onClick={p => this.props.history.push(p.key)}>
            {optionItem.icon}
            <span>{optionItem.title}</span>
          </Menu.Item>
        } else {
          return this.setMultistageMenu(optionItem)
        }
      })}
    </SubMenu>)
  };

  // 跳转至Home链接
  toHome = () => {
    // window.location.href = 'https://baidu.com'
    window.open('http://www.cjxh.org.cn')
  };

  // 跳转至个人中心
  toBaseinfo = () => {
    this.props.history.push("/memberpages/baseinfo")
    this.setState({
      sideBarActive: "/memberpages/baseinfo"
    })
  }

  // 预编辑用户
  editData = () => {
    let editData = JSON.parse(JSON.stringify(this.state.data));
    let userType = getUsertype();
    for (let i in editData) {
      if (editData[i] === 'undefined') {
        editData[i] = null
      }
    }
    if (userType === '个人用户') {
      editData.birthday = moment(editData.birthday, 'YYYY-MM-DD');
      if (editData.sex === '男') {
        editData.sex = 1
      } else {
        editData.sex = 2
      }
      if (personZcList.indexOf(editData.zc) == -1) {
        const nowZc = editData.zc
        editData.zc = "其他"
        editData.otherZc = nowZc
      }
      const orgObj = {
        label: editData.company,
        value: editData.orgId,
      };
      editData.company = orgObj;
    } else if (userType === '单位用户') {
      if (editData.contactSex === '男') {
        editData.contactSex = 1
      } else {
        editData.contactSex = 2
      }
      if (editData.linkFlag === '是') {
        editData.linkFlag = 1
      } else {
        editData.linkFlag = 2
      }
    } else if (userType === '专家用户') {
      editData.birthday = moment(editData.birthday, 'YYYY-MM-DD');
      editData.zcDate = moment(editData.zcDate ? editData.zcDate : moment(new Date()), 'YYYY-MM-DD');
      if (editData.sex === '男') {
        editData.sex = 1
      } else {
        editData.sex = 2
      }
      if (editData.isAcademician === '中科院院士') {
        editData.isAcademician = 1
      } else if (editData.isAcademician === '工程院院士') {
        editData.isAcademician = 2
      } else if (editData.isAcademician === '无') {
        editData.isAcademician = 3
      } else {
        editData.isAcademicianValue = editData.isAcademician;
        editData.isAcademician = 4
      }
      if (editData.isMember === '是') {
        editData.isMember = 1
      } else {
        editData.isMember = 2
      }
      if (technicalTitle.indexOf(editData.zc) == -1) {
        const nowZc = editData.zc
        editData.zc = "其他"
        editData.otherZc = nowZc
      }
      editData.organization = {
        label: editData.organization,
        value: editData.orgId,
      };
    }
    this.setState({
      tempRecord: editData,
      modalTitle: true,
      showModal: true,
      userType: userType
    })
  };

  // 正式编辑用户
  handleOk = e => {
    let data = e.data;
    let flag = e.flag;
    const that = this;
    // console.log(data, 11111)
    // console.log(flag, 22222)
    if (flag === '0') {
      updateMemberInfo({ user: data, type: flag }).then(res => {
        if (res.data.code === 200) {
          notification.success({
            message: '提示',
            description: '更新信息成功！'
          })
          that.setState({
            showModal: false
          })
          window.location.href = '/memberpages/baseinfo'
        } else {
          return notification.error({
            message: '提示',
            description: '系统错误，请重试！'
          })
        }
      })
    } else if (flag === '1') {
      updateMemberInfo({ user: data, type: flag }).then(res => {
        if (res.data.code === 200) {
          notification.success({
            message: '提示',
            description: '更新信息成功！'
          })
          that.setState({
            showModal: false
          })
          window.location.href = '/memberpages/baseinfo'
        } else {
          return notification.error({
            message: '提示',
            description: '系统错误，请重试！'
          })
        }
      })
    } else if (flag === '2') {
      updateMemberInfo({ user: data, type: flag }).then(res => {
        console.log(res, 33333)
        if (res.data.code === 200) {
          notification.success({
            message: '提示',
            description: '更新信息成功！'
          })
          that.setState({
            showModal: false
          })
          window.location.href = '/memberpages/baseinfo'
        } else {
          return notification.error({
            message: '提示',
            description: '系统错误，请重试！'
          })
        }
      })
    }
  };

  // 关闭编辑区域
  handleCancel = () => {
    this.setState({
      showModal: false
    })
  };

  // 显示帮助文档
  helpModal = () => {
    this.setState({
      showHelpModal: true
    })
  };

  // 关闭帮助文档
  handleCancelHelp = () => {
    this.setState({
      showHelpModal: false
    })
  };

  // 退出登录
  logout = () => {
    loginOut()
  };

  render() {
    let { children } = this.props;
    // console.log(this.state.tempRecord, 7777)
    // const allData = this.state.tempRecord
    // #1890ff
    const title = (
      <span>
        <img style={{ width: '36px', height: '36px' }} src='/logo2.png' alt='长江经济科技学会中心' />
        <span style={{ marginLeft: '15px', fontSize: '16px', color: '#fff' }}>会员信息系统</span>
      </span>
    );
    return (
      <Layout>
        <Spin spinning={false}>
          <Header className="header memner-header">
            <div className="logo">
              {title}
            </div>
            <div className="right-box">
              <div className='right-one' onClick={this.toHome}><span>长江技术经济学会首页</span></div>
              <div className="logOut">
                <span onClick={this.logout} >
                  <PoweroffOutlined />
                </span>
              </div>
            </div>
          </Header>
          <Layout>
            <Sider width={300} className="site-layout-background">
              <div className="site-image">
                <div className="site-avatar"><img src={avatar} alt="个人头像" /></div>
                <div className="avatar-info">
                  <span>{this.state.username}，您好</span>
                </div>
                <div className="avatar-info">
                  {/* <span>会员登记号：{this.state.usercode}</span> */}
                </div>
                <div className="avatar-info">
                  <span>级别：{this.state.levelcode}</span>
                </div>
                <div className="site-title">
                  <a onClick={this.toBaseinfo}>个人中心</a>
                  <a onClick={this.helpModal}>帮助</a>
                  {/* <span onClick={this.editData}>编辑</span> */}
                </div>
              </div>
              <Menu mode="inline" selectedKeys={[this.state.sideBarActive]} onClick={e => this.onSelect(e)}>
                {routes.map(route => {
                  if (route.children === undefined) {
                    if (route.isShow) {
                      return <Menu.Item key={route.path} onClick={p => this.props.history.push(p.key)}>
                        {route.icon}
                        <span>{route.title}</span>
                      </Menu.Item>
                    }
                  } else {
                    return this.setMultistageMenu(route)
                  }
                })}
              </Menu>
              <div className='ewCode'>
                <img src={ewCode} alt="" />
                <p>官方微信公众号</p>
                <span>WeChat Official Account</span>
              </div>
            </Sider>
            <Layout style={{ padding: '16px' }}>
              <Content
                className="site-layout-background"
                style={{
                  // margin: 16,
                  minHeight: 280,
                  borderRadius: 8,
                  boxSizing: "border-box"
                }}
              >
                {children}
                {/* {React.cloneElement(this.props.children, { params: this.state.tempRecord })} */}
                {/* {React.Children.map(this.props.children, child => {
                return React.cloneElement(child, {
                  params: () => {
                    return this.state.tempRecord
                  }
                })
              })} */}
              </Content>
            </Layout>
          </Layout>
          {this.state.showModal && getUsertype() === '个人用户' ? <Personmodal userType={this.state.userType} modalTitle={this.state.modalTitle} isModalVisible={this.state.showModal} dataBase={this.state.tempRecord} handleCancel={this.handleCancel} newDataBase={this.handleOk} /> : true}
          {this.state.showModal && getUsertype() === '单位用户' ? <Unitmodal userType={this.state.userType} modalTitle={this.state.modalTitle} isModalVisible={this.state.showModal} dataBase={this.state.tempRecord} handleCancel={this.handleCancel} newDataBase={this.handleOk} /> : true}
          {this.state.showModal && getUsertype() === '专家用户' ? <Expertsmodal userType={this.state.userType} modalTitle={this.state.modalTitle} isModalVisible={this.state.showModal} dataBase={this.state.tempRecord} handleCancel={this.handleCancel} newDataBase={this.handleOk} /> : true}
          {this.state.showHelpModal && getUsertype() === '个人用户' ? <Personhelp isModalVisible={this.state.showHelpModal} handleCancel={this.handleCancelHelp} /> : true}
          {this.state.showHelpModal && getUsertype() === '单位用户' ? <Unithelp isModalVisible={this.state.showHelpModal} handleCancel={this.handleCancelHelp} /> : true}
          {this.state.showHelpModal && getUsertype() === '专家用户' ? <Experthelp isModalVisible={this.state.showHelpModal} handleCancel={this.handleCancelHelp} /> : true}
        </Spin>
      </Layout>
    )
  }
}

export default withRouter(Frame)
