import React from 'react'
import { Form, Input, Steps, Checkbox, Button, Radio, Upload, message, Select } from 'antd'
// import { PoweroffOutlined } from '@ant-design/icons'
import { UploadOutlined } from '@ant-design/icons'
import { downTable, uploadUnitData, judgeAccount, checkOrgName } from '../../service/api'
import './RegisterUnit.scss'
import logo from '../../assets/images/logo.png'
import Constitution from '../../components/constitution/constitution'
import Registerwait from '../../components/registerWait/registerwait'
import qs from 'qs'
import { checkPassword } from '../../utils/stringUtil'
const { Option } = Select;
const { Step } = Steps;

const unifiedCreditCodeReg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
const phoneReg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
const memberTagOption = ['理事长单位', '常务副理事长单位', '副理事长单位', '常务理事单位', '理事单位', '会员单位'];
const SpaceReg = /^(?!(\s+$))/;//纯空格
const formItemLayout = {
  labelCol: {
    xs: {
      span: 6,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    }
  }
};

class RegisterUnit extends React.Component {
  state = {
    currentStep: 0,
    showContentOne: true,
    showContentTwo: false,
    showContentThree: false,
    checkOneValue: false,
    checkTwoValue: false,
    loading: false,
    uploadFile: null,
    registerData: {
      contactSex: 1,
      linkFlag: 1
    },
    isUpdate: false
  };

  componentDidMount() {
    let url = this.props.location.search;
    // console.log(url, 11111)
    if (url) {
      let parameter = url.split('?')[1];
      // console.log(parameter, 11111)
      let parameterValue = parameter.split('=')[1];
      // 获取此账户的值，并重新设定registerData，isUpdate改为true
      this.setState({
        showContentOne: false,
        showContentTwo: true,
        currentStep: 1
      })
    }
  }

  checkAccount = e => {
    let account = e.target.value;
    if (null === account || typeof account === undefined || "undefined" === account || "" === account) return;
    const param = qs.stringify({ 'account': account, 'type': 'group' });
    judgeAccount(param).then(res => {
      let data = res.data;
      if (data.code === 2008) {
        //账号已存在
        return message.error('此统一信用代码已注册，请核实后再填写！')
      }
    })
  };

  checkOrgName = e => {
    let account = e.target.value;
    if (null === account || typeof account === undefined || "undefined" === account || "" === account) return;
    const param = qs.stringify({ 'orgName': account });
    checkOrgName(param).then(res => {
      let data = res.data;
      if (data.code === 2008) {
        //账号已存在
        return message.error('此单位名称已注册，请核实后再填写！')
      }
    })
  };

  // 长江技术经济协会章程确认
  checkOne = e => {
    // console.log(e, 111)
    let checkOneValue = e.target.checked;
    this.setState({
      checkOneValue: checkOneValue
    })
  };

  queryOne = () => {
    let dom = document.getElementsByClassName('unitContent')[0];
    if (this.state.checkOneValue) {
      dom.scrollTop = 0;
      this.setState({
        showContentOne: false,
        showContentTwo: true,
        currentStep: 1
      })
    }
  };

  // 单位表格
  // checkTwo = e => {
  //   // console.log(e, 111)
  //   let checkTwoValue = e.target.checked
  //   this.setState({
  //     checkTwoValue: checkTwoValue
  //   })
  // }

  // queryTwo = () => {
  //   if(this.state.checkTwoValue) {
  //     this.setState({
  //       showContentOne: false,
  //       showContentTwo: false,
  //       showContenThree: true,
  //       currentStep: 2
  //     })
  //   }
  // }

  // 上传照片前，手动上传照片
  beforeUpload = file => {
    // let fileData = e.name.split('.')
    // if(fileData[fileData.length - 1] !== 'pdf'
    //     && fileData[fileData.length - 1] !== 'png'
    //     && fileData[fileData.length - 1] !== 'jpg'
    //     && fileData[fileData.length - 1] !== 'jpeg'
    //     && fileData[fileData.length - 1] !== 'doc'
    //     && fileData[fileData.length - 1] !== 'docx'){
    //   return message.warning('请上传规定类型的文件')
    // }

    const isType = file.type === 'image/jpeg'
      || file.type === 'image/png'
      || file.type === 'image/jpg'
      || file.type === 'application/pdf';

    if (!isType) {
      message.error('请上传图片或者pdf类型的文件!');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('文件必须小于20MB!');
    }
    return isType && isLt2M;
  };

  // 上传文件
  normFile = e => {
    this.setState({
      uploadFile: e.file
    });
    if (Array.isArray(e)) {
      return e;
    }
    //默认路径一般都会失败，但因为只是需要临时的文件数据而非实时上传所以直接默认成功状态就行
    if (e.fileList && e.fileList.length) {
      e.fileList.map(file => {
        file.status = 'done';
        file.error = '';
        file.url = file.url ? file.url : window.URL.createObjectURL(file.originFileObj);
        return file;
      })
    }
    // console.log(111, e.fileList)
    return e && e.fileList;
    // return false
  };

  // 下载注册表格
  download = () => {
    downTable().then(res => {
      // console.log(res, 1111)
      let data = res.data;
      // console.log(data, 1111)
      let url = window.URL.createObjectURL(new Blob([data]));
      // console.log(url, 22222)
      let filename = '单位注册申请表-电子.docx';
      let a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      a.click()
      // document.body.removeChild(a)
      // let link = document.createElement('a')
      // link.style.display = 'none'
      // link.href = url
      // link.setAttribute('download', 'hello.doc')
      // document.body.appendChild(link)
      // link.click()
      // URL.revokeObjectURL(url)
      // document.body.removeChild(link)
    })
  };

  // 注册
  onFinish = e => {
    this.setState({
      loading: true
    }, () => {
      // let uploadFile = this.state.uploadFile
      let allData = e;
      let formData = new FormData();
      // formData.append('multiConfirmFile', uploadFile)
      if (allData.contactSex === 1) {
        allData.contactSex = '男'
      } else {
        allData.contactSex = '女'
      }
      if (allData.linkFlag === 1) {
        allData.linkFlag = '是'
      } else {
        allData.linkFlag = '否'
      }
      // console.log(allData, 7777)
      // allData.multiConfirmFile = uploadFile
      allData.confirmStatus = '0';
      // let keys = Object.keys(allData)
      // console.log(keys, 1111)
      // for(let i in allData){
      //   console.log(keys[i], 1111)
      //   console.log(allData[i], 22222)
      //   formData.append(keys[i], allData[i])
      // }
      formData.append('name', allData.name?.trim());
      formData.append('address', allData.address?.trim());
      formData.append('zip', allData.zip?.trim());
      formData.append('corporate', allData.corporate?.trim());
      formData.append('cpcode', allData.cpcode);
      formData.append('headName', allData.headName?.trim());
      formData.append('headZwzc', allData.headZwzc?.trim());
      formData.append('headMobileTel', allData.headMobileTel);
      formData.append('headEmail', allData.headEmail);
      formData.append('bussinessScope', allData.bussinessScope?.trim());
      formData.append('contactName', allData.contactName?.trim());
      formData.append('contactSex', allData.contactSex);
      formData.append('contactZwzc', allData.contactZwzc?.trim());
      formData.append('contactMobileTel', allData.contactMobileTel);
      formData.append('contactQQ', allData.contactQQ?.trim());
      formData.append('contactFax', allData.contactFax?.trim());
      formData.append('contactWechat', allData.contactWechat?.trim());
      formData.append('contactEmail', allData.contactEmail);
      formData.append('contactAddress', allData.contactAddress?.trim());
      formData.append('pwd', allData.pwd);
      formData.append('webUrl', allData.webUrl?.trim());
      formData.append('linkFlag', allData.linkFlag);
      if (allData.multiConfirmFile) {
        formData.append('multiConfirmFile', allData.multiConfirmFile[0].originFileObj)
      } else {
        formData.append('multiConfirmFile', '')
      }
      formData.append('confirmStatus', allData.confirmStatus);
      formData.append('memberTag1', allData.memberTag1);
      formData.append('vId', `unit_${new Date().getTime()}${allData.headMobileTel}`);
      uploadUnitData(formData).then(res => {
        let data = res.data;
        this.setState({
          loading: false
        });
        if (data.code === 200) {
          message.success('注册成功，请等待审核！');
          this.setState({
            showContentTwo: false,
            showContentThree: true,
            currentStep: 2
          })
        } else if (data.code === 2008) {
          return message.error('此统一信用代码已注册，请核实后再填写')
        } else {
          return message.error('注册失败，请重新注册！')
        }
      }).catch((error) => {
        this.setState({
          loading: false
        });
        // console.log("catch", error);
        return message.error('注册异常请尝试重新提交一次，如果您是第二次见到此提示请联系管理员！')
      })
    })
  };

  // 返回登录页
  backLogin = () => {
    // console.log('返回', 22222)
    this.props.history.push('/login')
  };

  render() {
    return (
      <div className="registerUnit">
        <div className="loginLog">
          <img className="logoImage" src={logo} alt="logo" />
        </div>
        <div className="unitBox">
          <div className="unitStep">
            <Steps current={this.state.currentStep}>
              <Step title="第一步" description="认真阅读学会章程" />
              <Step title="第二步" description="填写单位会员注册信息" />
              <Step title="第三步" description="注册温馨提示" />
            </Steps>
          </div>
          <div className="unitContent">
            <div className={`${this.state.showContentOne ? '' : 'hideContent'}`}>
              <Constitution />
              <div className="constitutionConfirm">
                <div className="checkBox">
                  <Checkbox checked={this.state.checkOneValue} onChange={e => this.checkOne(e)}>本单位已阅读，并同意遵守长江技术经济学会章程</Checkbox>
                </div>
                <Button style={{ width: "150px" }} className={`${this.state.checkOneValue ? 'checked' : 'noChecked'}`} onClick={this.queryOne}>下一步</Button>
              </div>
            </div>
            <div className={`${this.state.showContentTwo ? '' : 'hideContent'}`}>
              {/* <Unitautonomy /> */}
              <div className="tableConfirm">
                <Form
                  {...formItemLayout}
                  name="register"
                  initialValues={this.state.registerData}
                  onFinish={this.onFinish}
                  scrollToFirstError
                >
                  <div className="baseTitle"><span>单位基本信息</span></div>
                  <Form.Item
                    name="name"
                    label="单位名称"
                    onBlur={this.checkOrgName.bind(this)}
                    rules={[
                      {
                        required: true,
                        message: '请填写单位名称',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="memberTag1"
                    label="学会职务"
                    rules={[
                      {
                        required: true,
                        message: '请选择学会职务',
                      }
                    ]}
                  >
                    <Select placeholder="请选择学会职务">
                      {memberTagOption.map((item, index) => {
                        return <Option value={item} key={index}>{item}</Option>
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="address"
                    label="通讯地址"
                    rules={[
                      {
                        required: true,
                        message: '请填写单位地址',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="zip"
                    label="邮编"
                    rules={[
                      {
                        required: true,
                        message: '请填写单位邮编地址',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="corporate"
                    label="法定代表人"
                    rules={[
                      {
                        required: true,
                        message: '请填写单位法定代表人',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="cpcode"
                    label="统一信用代码"
                    onBlur={this.checkAccount}
                    rules={[
                      {
                        required: true,
                        message: '请填写单位统一信用代码',
                      },
                      {
                        pattern: unifiedCreditCodeReg,
                        message: '请填写正确单位统一信用代码'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="headName"
                    label="单位负责人"
                    rules={[
                      {
                        required: true,
                        message: '请填写单位负责人',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="headZwzc"
                    label="职称/职务"
                    rules={[
                      {
                        required: true,
                        message: '请填写单位负责人职称/职务',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="headMobileTel"
                    label="电话（手机）"
                    rules={[
                      {
                        required: true,
                        message: '请填写单位负责人电话',
                      },
                      {
                        pattern: phoneReg,
                        message: '请填写正确手机联系方式'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="headEmail"
                    label="电子邮箱"
                    rules={[
                      {
                        type: 'email',
                        message: '输入不是有效电子邮箱',
                      },
                      {
                        required: true,
                        message: '请填写电子邮箱',
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="bussinessScope"
                    label="主要业务范围"
                    rules={[
                      {
                        required: true,
                        message: '请填写单位主要业务范围',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="baseTitle"><span>联系人基本信息</span></div>
                  <Form.Item
                    name="contactName"
                    label="姓名"
                    rules={[
                      {
                        required: true,
                        message: '请填写联系人姓名',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="contactSex"
                    label="性别"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Radio.Group name="contactSex">
                      <Radio value={1}>男</Radio>
                      <Radio value={2}>女</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="contactZwzc"
                    label="职称/职务"
                    rules={[
                      {
                        required: true,
                        message: '请填写联系人职称/职务',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="contactMobileTel"
                    label="电话"
                    rules={[
                      {
                        required: true,
                        message: '请填写联系人电话',
                      },
                      {
                        pattern: phoneReg,
                        message: '请填写正确电话联系方式'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="contactQQ"
                    label="QQ"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="contactFax"
                    label="传真"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="contactWechat"
                    label="微信"
                    rules={[
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="contactEmail"
                    label="电子邮箱"
                    rules={[
                      {
                        type: 'email',
                        message: '输入不是有效电子邮箱',
                      },
                      {
                        required: true,
                        message: '请填写电子邮箱',
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="contactAddress"
                    label="地址"
                    rules={[
                      {
                        required: true,
                        message: '请填写地址',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="baseTitle"><span>其他</span></div>
                  <Form.Item
                    name="pwd"
                    label="登录密码"
                    rules={[
                      {
                        validator: checkPassword
                      },
                      {
                        required: true,
                        message: '请填写登录密码!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="confirmPwd"
                    label="确认密码"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: '请确认您的密码!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('pwd') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('两次密码不一致!');
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name="webUrl"
                    label="单位网址"
                    rules={[
                      {
                        required: true,
                        message: '请填写单位网址',
                      },
                      {
                        pattern: SpaceReg,
                        message: '输入内容不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="linkFlag"
                    label="是否同意本会链接"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Radio.Group name="linkFlag">
                      <Radio value={1}>是</Radio>
                      <Radio value={2}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="multiConfirmFile"
                    label="上传单位注册表格"
                    valuePropName="fileList"
                    getValueFromEvent={this.normFile}
                    help="备注：表格经单位盖章后，复印件寄送到长江技术经济学会或扫描件发送到学会邮箱office@cjxh.org.cn。文件大小不得超过20MB"
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Upload action='/' name="multiConfirmFile" accept=".pdf,.jpg, .jpeg, .png" listType="text" beforeUpload={this.beforeUpload} >
                      <Button icon={<UploadOutlined />}>请上传单位注册表格（需单位盖章）</Button>
                    </Upload>
                  </Form.Item>
                  <div className="buttonItem">
                    <Button style={{ width: "180px", marginRight: '30px' }} type="primary" onClick={this.download}>
                      下载单位注册表格
                    </Button>
                    {this.state.isUpdate ? <Button style={{ width: "180px" }} type="primary" loading={this.state.loading} htmlType="submit">
                      更改提交
                    </Button> : <Button style={{ width: "180px" }} type="primary" loading={this.state.loading} htmlType="submit">
                      提交
                    </Button>}
                  </div>
                </Form>
              </div>
            </div>
            <div className={`${this.state.showContentThree ? '' : 'hideContent'}`}>
              <Registerwait />
              <div className="contentThreeButton">
                <Button style={{ width: "150px" }} type="primary" onClick={this.backLogin}>返回登录页</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="loginFooter">
          <span className="footerSign">©2021 长江技术经济学会 版权所有</span>
        </div>
      </div>
    )
  }
}

export default RegisterUnit
