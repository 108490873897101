import React from 'react'
import { Form, Input, Radio, Modal, Button, Select } from 'antd'
import './Unitmodal.scss'
const { Option } = Select;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  }
};
const memberTagOption = ['理事长单位', '常务副理事长单位', '副理事长单位', '常务理事单位', '理事单位', '会员单位'];

const unifiedCreditCodeReg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
const phoneReg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
const SpaceReg = /^(?!(\s+$))/;//纯空格

class Unitmodal extends React.Component {
  state = {
    loading: false
  };

  // 取消
  handleCancel = () => {
    const handleCancel = this.props.handleCancel;
    handleCancel()
  };

  // 提交/添加
  handleOk = e => {
    // console.log(e, 3333)
    let allData = e;
    let toP = this.props.newDataBase;

    if (allData.contactSex === 1) {
      allData.contactSex = '男'
    } else {
      allData.contactSex = '女'
    }

    if (allData.linkFlag === 1) {
      allData.linkFlag = '是'
    } else {
      allData.linkFlag = '否'
    }

    allData.address = allData.address?.trim();
    allData.zip = allData.zip?.trim();
    allData.headName = allData.headName?.trim();
    allData.corporate = allData.corporate?.trim();
    allData.headZwzc = allData.headZwzc?.trim();
    allData.bussinessScope = allData.bussinessScope?.trim();
    allData.contactName = allData.contactName?.trim();
    allData.contactZwzc = allData.contactZwzc?.trim();
    allData.contactMobileTel = allData.contactMobileTel?.trim();
    allData.contactQQ = allData.contactQQ?.trim();
    allData.contactFax = allData.contactFax?.trim();
    allData.contactWechat = allData.contactWechat?.trim();
    allData.contactAddress = allData.contactAddress?.trim();
    allData.webUrl = allData.webUrl?.trim();

    toP({ data: allData, flag: '1' })
  };

  render() {
    return (
      <Modal
        width={800}
        destroyOnClose
        centered
        title={`${this.props.modalTitle ? '编辑信息' : '查看信息'}`}
        visible={this.props.isModalVisible}
        onCancel={this.handleCancel}
        footer={null}
      >
        <div style={{ width: "100%" }}>
          <Form
            {...layout}
            name="register"
            initialValues={this.props.dataBase}
            onFinish={this.handleOk}
            labelAlign="center"
          >
            <div className="baseTitle"><span>单位基本信息</span></div>
            <Form.Item
              name="name"
              label="单位名称"
              rules={[
                {
                  required: true,
                  message: '请填写单位名称',
                }
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="memberTag1"
              label="学会职务"
              rules={[
                {
                  required: true,
                  message: '请选择学会职务',
                }
              ]}
            >
              <Select placeholder="请选择学会职务">
                {memberTagOption.map((item, index) => {
                  return <Option value={item} key={index}>{item}</Option>
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="address"
              label="通讯地址"
              rules={[
                {
                  required: true,
                  message: '请填写单位地址',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="zip"
              label="邮编"
              rules={[
                {
                  required: true,
                  message: '请填写单位邮编地址',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="corporate"
              label="法定代表人"
              rules={[
                {
                  required: true,
                  message: '请填写单位法定代表人',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="cpcode"
              label="统一信用代码"
              rules={[
                {
                  required: true,
                  message: '请填写单位统一信用代码',
                },
                {
                  pattern: unifiedCreditCodeReg,
                  message: '请填写正确单位统一信用代码'
                }
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="headName"
              label="单位负责人"
              rules={[
                {
                  required: true,
                  message: '请填写单位负责人',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="headZwzc"
              label="职称/职务"
              rules={[
                {
                  required: true,
                  message: '请填写单位负责人职称/职务',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="headMobileTel"
              label="电话（手机）"
              rules={[
                {
                  required: true,
                  message: '请填写单位负责人电话',
                },
                {
                  pattern: phoneReg,
                  message: '请填写正确手机联系方式'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="headEmail"
              label="电子邮箱"
              rules={[
                {
                  type: 'email',
                  message: '输入不是有效电子邮箱',
                },
                {
                  required: true,
                  message: '请填写电子邮箱',
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="bussinessScope"
              label="主要业务范围"
              rules={[
                {
                  required: true,
                  message: '请填写单位主要业务范围',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <div className="baseTitle"><span>联系人基本信息</span></div>
            <Form.Item
              name="contactName"
              label="姓名"
              rules={[
                {
                  required: true,
                  message: '请填写联系人姓名',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            {this.props.modalTitle ? <Form.Item
              name="contactSex"
              label="性别"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Radio.Group name="contactSex">
                <Radio value={1}>男</Radio>
                <Radio value={2}>女</Radio>
              </Radio.Group>
            </Form.Item> : <Form.Item
              name="contactSex"
              label="性别"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>}
            <Form.Item
              name="contactZwzc"
              label="职称/职务"
              rules={[
                {
                  required: true,
                  message: '请填写联系人职称/职务',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="contactMobileTel"
              label="电话"
              rules={[
                {
                  required: true,
                  message: '请填写联系人电话',
                },
                {
                  pattern: phoneReg,
                  message: '请填写正确手机联系方式'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="contactQQ"
              label="QQ"
              rules={[
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="contactFax"
              label="传真"
              rules={[
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="contactWechat"
              label="微信"
              rules={[
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="contactEmail"
              label="电子邮箱"
              rules={[
                {
                  type: 'email',
                  message: '输入不是有效电子邮箱',
                },
                {
                  required: true,
                  message: '请填写电子邮箱',
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="contactAddress"
              label="地址"
              rules={[
                {
                  required: true,
                  message: '请填写地址',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <div className="baseTitle"><span>其他</span></div>
            <Form.Item
              name="webUrl"
              label="单位网址"
              rules={[
                {
                  required: true,
                  message: '请填写单位网址',
                },
                {
                  pattern: SpaceReg,
                  message: '输入内容不能为空'
                }
              ]}
            >
              <Input />
            </Form.Item>
            {this.props.modalTitle ? <Form.Item
              name="linkFlag"
              label="是否同意本会链接"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Radio.Group name="linkFlag">
                <Radio value={1}>是</Radio>
                <Radio value={2}>否</Radio>
              </Radio.Group>
            </Form.Item> : <Form.Item
              name="linkFlag"
              label="是否同意本会链接"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>}

            {/* <Form.Item> */}
            <div className="buttonWrapper">
              {this.props.modalTitle ? <Button type="primary" htmlType="submit" loading={this.state.loading}>
                保存
              </Button> : true}
            </div>
            {/* </Form.Item> */}
          </Form>
        </div>
      </Modal>
    )
  }
}

export default Unitmodal
